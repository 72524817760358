import { createSlice } from '@reduxjs/toolkit';

export const homeDataSlice = createSlice({
	name: 'homepage',
	initialState: {
		loadingCategories: true,
		categories: [],
		banner: [],
		campaign: [],
		catalogue: [],
		newArrivals: [],
		productDetails: [],
		productFilter: [],
		selectedCategory: 0,
		stateList: [],
		selectedType: 0,
		internationalheader: true,
		detailInternationalHeader: false,
		footerShow: false,
		staticblock: {},
		mobileScroll: false,
		title: '',
	},
	reducers: {
		// Redux Toolkit allows us to write "mutating" logic in reducers. It
		// doesn't actually mutate the state because it uses the immer library,
		// which detects changes to a "draft state" and produces a brand new
		// immutable state based off those changes
		saveCatagory: (state, action) => {
			state.categories = action.payload;
		},

		saveBanner: (state, action) => {
			state.banner = action.payload;
		},
		saveNewArrival: (state, action) => {
			state.newArrivals = action.payload;
		},
		saveProductListing: (state, action) => {
			state.productFilter = action.payload;
		},
		saveSelectedCategory: (state, action) => {
			state.selectedCategory = action.payload;
		},
		saveSelectedType: (state, action) => {
			state.selectedType = action.payload;
		},
		saveStateList: (state, action) => {
			state.stateList = action.payload;
		},
		saveInternationalHeader: (state, action) => {
			state.internationalheader = action.payload;
		},
		saveDeatailInternationalHeader: (state, action) => {
			state.detailInternationalHeader = action.payload;
		},
		saveStaticBlock: (state, action) => {
			state.staticBlock = action.payload;
		},
		saveMobileScroll: (state, action) => {
			state.mobileScroll = action.payload;
		},
		saveTitle: (state, action) => {
			console.log(action.payload);
			state.title = action.payload;
		},
		setLoadingCategories: (state, action) => {
			state.loadingCategories = action.payload
		},
		setFooterShow: (state, action) => {
			console.log(action.payload);
			state.footerShow = action.payload
		}
	},
});

export const {
	saveCatagory,
	saveBanner,
	saveNewArrival,
	saveProductListing,
	saveSelectedCategory,
	saveSelectedType,
	saveStateList,
	saveInternationalHeader,
	saveDeatailInternationalHeader,
	saveStaticBlock,
	saveMobileScroll,
	saveTitle,
	setLoadingCategories,
	setFooterShow
} = homeDataSlice.actions;

export default homeDataSlice.reducer;
